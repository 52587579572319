
.ethelheldSanctuary{
	.bubble{
		position:absolute;
		width: 50px;
		height: 50px;
		border-radius: 10px;
		background-color: rgba(131,172,255,.2);
		border:2px solid rgb(131,172,255);
	}
}

img.char{
	object-fit:cover !important;   			
}

.warroom{
	&.bubble{
		top:780px;
		left:2110px;
	}
	img{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 65% 29%;   		
	}

	.armor{
		width: 100%;
		height: initial;
		object-fit: contain;
		object-position: initial;
	}
}

.thelodge{
	&.bubble{
		top: 825px;
    	left: 2202px;
	}
	img{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 68% 32%;   
	}
	.img-fluid{
		width: 100% !important;
		height: auto;
	}
}

.traininggrounds{
	&.bubble{
		top: 820px;
    	left: 2375px;
	}

	img{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 74% 32%;   
	}
}

.warehouse{
	&.bubble{
		top: 706px;
    	left: 2323px;
	}

	img{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 73% 26%;   
	}
}

.mineentrance{
	&.bubble{
		top: 609px;
    	left: 2282px;
	}

	img{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 71.5% 22%; 
	}
}

.beastiary{
	&.bubble{
		top: 837px;
    	left: 1932px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 60% 32%; 
   		margin-left: auto;
   		margin-right: auto;
	}
}

.pilgrims1{
	&.bubble{
		top: 537px;
    	left: 873px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 25% 19%; 
   		margin-left: auto;
   		margin-right: auto;
	}
}

.pilgrims2{
	&.bubble{
		top: 431px;
    	left: 793px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 22% 15%; 
   		margin-left: auto;
   		margin-right: auto;
	}
}

.inn{
	&.bubble{
		top: 337px;
    	left: 1007px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 29% 11%; 
   		margin-left: auto;
   		margin-right: auto;
	}
}

.oldwell{
	&.bubble{
		top: 570px;
    	left: 1400px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 42% 20%; 
   		margin-left: auto;
   		margin-right: auto;
	}
}

.statue{
	&.bubble{
		top: 342px;
    	left: 1471px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 44% 11%;
   		margin-left: auto;
   		margin-right: auto;
	}
}

.grove{
	&.bubble{
		top: 187px;
   		left: 1882px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 58% 4%;
   		margin-left: auto;
   		margin-right: auto;
	}
}

.cemetary{
	&.bubble{
		top: 176px;
    	left: 2552px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 82% 4%;
   		margin-left: auto;
   		margin-right: auto;
	}
}

.goddressroad{
	&.bubble{
		top: 1193px;
    	left: 52px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 0% 45%;
   		margin-left: auto;
   		margin-right: auto;
	}
}

.ethelheldroad{
	&.bubble{
		top: 2223px;
    	left: 2162px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 67% 91%;
   		margin-left: auto;
   		margin-right: auto;
	}

	img{
		object-fit: cover;
	}
}

.sanctuary{
	&.bubble{
		top: 463px;
    	left: 2832px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 89% 16%;
   		margin-left: auto;
   		margin-right: auto;
	}

	img{
		object-fit: cover;
	}
}

.forge{
	&.bubble{
		top: 728px;
    	left: 1944px;
	}

	img.small{
		height: 250px;
   		width: 300px;
   		object-fit: none;
   		object-position: 89% 16%;
   		margin-left: auto;
   		margin-right: auto;
	}

	img{
		object-fit: cover;
	}
}

