//world character styles
.origin{
  .card{
    button{
      margin-bottom: 5px;
    }

    &.active{
      box-shadow: 0px 2px 5px 5px;
    }
  }
}

.vadir.origin{
  img.contain{
    object-fit: contain;
  }
}

.azaine.origin{
  .h500{
    max-height: 380px;
  }
  .contain{
    object-fit: contain;
  }
  .cover{
    object-fit: cover;
  }
}