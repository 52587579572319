.inventory{

}

.inventory-char{
  .belt{
    .pouch{
      border: 1px solid #ccc;
      border-radius: 20rem;
      text-align: center;
      margin-top: 1rem;
    }
  }
  .backpack{
    .item{
      border-radius: 10rem;
      text-align: center;
      min-height: 5rem;
    }
  }
}