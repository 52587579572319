
@import "ember-cli-bootstrap-4/bootstrap";
@import url('https://fonts.googleapis.com/css?family=MedievalSharp');
@import "irilleum";
@import "ethelheldSanctuary";
@import "story";
@import "inventory";
@import "skills";

body{
	font-family: 'MedievalSharp', cursive;
	font-size: 1.2em;
}

a:hover{
	text-decoration: none;
}

.itm-highlight {
	box-shadow: 0 2px 3px 4px;
	}
	
.modalmin {
	min-width: 30rem;
}

.weap-ability-list{
	min-height:350px;
}
